import apiClient from "./config";

interface IDotExceptionPayload {
  type: "DOT_MISSING" | "DOT_INVALID";
  name: string;
  phone_number: string;
  email: string;
}

export const submitDotException = async (data: IDotExceptionPayload) => {
  try {
    const response = await apiClient.post(`ai-caller/dot-exception`, data);
    return response.data;
  } catch (error) {
    throw new Error("Failed to submit DOT exception");
  }
};
