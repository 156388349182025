import * as Yup from "yup";

export const companyDetailsSchema = Yup.object({
  company_name: Yup.string().required("Company Name is required"),
  dot: Yup.number().required("DOT Number is required"),
  mc: Yup.number().required("MC Number is required"),
});

export const contactInfoSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  company_phone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone number is not valid")
    .required("Phone number is required"),
  business_location: Yup.string().required("Business location is required"),
});

export const additionalDetailsSchema = Yup.object({
  t_code: Yup.string().optional(),
  default_equipment_type: Yup.string().required("Equipment type is required"),
  permits: Yup.array().of(Yup.string()).optional(),
  general_preferences: Yup.string().optional(),
  universal_questions: Yup.string().optional(),
});

export const settingsSchema = Yup.object({
  company_legal_name: Yup.string().required("Company Name is required"),
  dot: Yup.number().required("DOT Number is required"),
  mc: Yup.number().required("MC Number is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  phone_number: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone number is not valid")
    .required("Phone number is required"),
  company_address: Yup.string().required("Business location is required"),
  ...additionalDetailsSchema.fields,
});

export const AuthSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});
