import React from "react";
import "./HowToPage.scss";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import { ReactComponent as IconLeft } from "images/arrow_left.svg";
import { ReactComponent as IconArrow } from "images/audio_arrow.svg";
import { useNavigate } from "react-router-dom";
import useIsMobile from "hooks/useIsMobile";
import { assistantConfiguration, copilotConfiguration } from "utils/constants";
import { Button } from "components/Button/Button";
import { toast } from "react-toastify";
import { Audio } from "components/Audio/Audio";

interface IHowToPageProps {
  mode: "copilot" | "assistant";
}

const HowToPage = ({ mode }: IHowToPageProps) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const configuration =
    mode === "assistant" ? assistantConfiguration : copilotConfiguration;
  const steps = configuration?.steps;

  const handleDownloadExtension = () => {
    if (isMobile) {
      toast.info("Please use a desktop browser to download the extension.");
      return;
    } else {
      navigate("/install-extension");
    }
  };

  const handleRedirectToRequest = () => {
    navigate("/request");
  };

  return (
    <div className="how">
      <div className="how__container">
        <div className="how__back-button">
          <IconArrowLeft onClick={() => navigate("/")} className="back-icon" />
          <div onClick={() => navigate("/")} className="back-label">
            <IconLeft />
            <span>Back to Home</span>
          </div>
        </div>
        <div className="how__heading">{configuration.pageTitle}</div>

        <div className="how__content">
          <div className="how__content-container">
            <div className="how__header">
              <div className="how__header-title">{configuration.title}</div>
              <div className="how__header-description">
                {configuration.description}
              </div>
            </div>
            <div className="how__header-title how-it-works-title">
              How it works
            </div>

            <div className="stepper">
              {steps.map((step, index) => (
                <div className="stepper__step" key={index}>
                  <div
                    className={`stepper__content ${
                      index % 2 !== 0 ? "reverse" : ""
                    }`}
                  >
                    <div className="stepper__image">
                      {step?.appendAudio ? (
                        <div className="listen">
                          <IconArrow className="listen__arrow" />
                          <div className="listen__wrapper">
                            <div className="listen__container">
                              <div className="listen__pills">
                                <div className="listen__text-pill">
                                  <div className="icon">🎧</div>
                                  <span>Listen to it in action</span>
                                </div>
                                <Audio />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <img src={step.image} alt={`Step ${index + 1}`} />
                      )}
                    </div>
                    <div className="stepper__text">
                      <div className="stepper__text-title">{step.title}</div>
                      <div className="stepper__text-description">
                        {step.description}
                      </div>
                    </div>
                  </div>
                  <div className="stepper__number">
                    <div className="number-circle">{index + 1}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="how__footer">
              <div className="how__footer-text">
                {mode === "assistant"
                  ? "Start a New Request"
                  : "Add EMPWR Assistant to your Chrome browser"}
              </div>
              <Button
                text={
                  mode === "assistant"
                    ? "Start a New Request"
                    : "Download the Chrome Extension"
                }
                onClick={
                  mode === "assistant"
                    ? handleRedirectToRequest
                    : handleDownloadExtension
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPage;
