import ExtensionImage1 from "images/extension-tutorial-1.png";
import ExtensionImage2 from "images/extension-tutorial-2.png";
import ExtensionImage3 from "images/extension-tutorial-3.png";
import ExtensionImage4 from "images/extension-tutorial-4.png";
import ExtensionImage5 from "images/extension-tutorial-5.png";

import CopilotImage1 from "images/copilot-tutorial-1.png";
import CopilotImage2 from "images/copilot-tutorial-2.png";
import CopilotImage3 from "images/copilot-tutorial-3.png";
import CopilotImage4 from "images/copilot-tutorial-4.png";
import CopilotImage5 from "images/copilot-tutorial-5.png";
import CopilotImage6 from "images/copilot-tutorial-6.png";

export const permitOptions = [
  { value: "Tanker endorsement", label: "Tanker endorsement" },
  { value: "Hazmat", label: "Hazmat" },
  { value: "TWIC", label: "TWIC" },
  { value: "Oversize/overweight", label: "Oversize/overweight" },
];

export const assistantConfiguration = {
  pageTitle: "How to Find Spot Freight",
  title: "Find spot freight with EMPWR Assistant",
  description:
    "EMPWR Assistant contacts brokers for you to confirm if loads are available and collect the details that weren't posted by the broker. When you submit a new request, your EMPWR Assistant will find matching loads from our network and begin calling the brokers.",
  steps: [
    {
      image: ExtensionImage1,
      title: "Enter a load request",
      description:
        "Enter the details and preferences of the load you're looking for.",
    },
    {
      image: ExtensionImage2,
      title: "Add a question",
      description:
        "Add an optional question for your assistant to ask brokers when calling about loads.",
    },
    {
      image: ExtensionImage3,
      title: "Confirm your search",
      description:
        "You can confirm or make edits to your search before your EMPWR Assistant begins calling brokers.",
    },
    {
      image: ExtensionImage4,
      title: "Let your assistant make the calls",
      appendAudio: true,
      description:
        "Your EMPWR Assistant reaches out to multiple brokers at once, confirming availability and details.",
    },
    {
      image: ExtensionImage5,
      title: "Review load details gathered by your assistant",
      description:
        "You'll receive an email summarizing the results of your assistants calls, including availability and details, as soon as they're completed.",
    },
  ],
};

export const copilotConfiguration = {
  pageTitle: "How to Use Copilot",
  title: "Find spot freight with Copilot",
  description:
    "With EMPWR Copilot you can have your EMPWR Assistant make calls to brokers directly from your Truckstop account.  Increase your efficiency and find more options as you work the loadboard by having your EMPWR Assistant make the calls and confirm load details for you.",
  steps: [
    {
      image: CopilotImage1,
      title: "Install the Copilot Chrome extension",
      description:
        "Install the EMPWR Assistant Copilot extension from the Chrome Store",
    },
    {
      image: CopilotImage2,
      title: "Pin EMPWR Assistant in Your Browser Bar",
      description:
        "Use the extension icon in your browser bar to pin EMPWR Assistant in your tool bar for easier access.",
    },
    {
      image: CopilotImage3,
      title: "Turn on EMPWR Assistant Truckstop",
      description:
        "In your Truckstop account, use the icon in your browser bar to turn EMPWR Assistant on or off.",
    },
    {
      image: CopilotImage4,
      title: "Initiate calls to brokers",
      description:
        "Select the loads that you want your EMPWR Assistant to call.",
    },
    {
      image: CopilotImage5,
      title: "Let your assistant make the calls",
      appendAudio: true,
      description:
        "Your EMPWR Assistant reaches out to multiple brokers at once, confirming availability and details.",
    },
    {
      image: CopilotImage6,
      title: "Review load details gathered by your assistant",
      description:
        "View the details gathered by your EMPWR Assistant as soon as calls finish.",
    },
  ],
};