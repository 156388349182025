export const EVENTS = {
  NEW_SIGNUP: "New Signup",
  COMPANY_INFO_SAVED: "Company Info Saved",
  NEW_LOGIN: "New Login",
  REQUEST_SUBMITTED: "Request Submitted",
  LOAD_SEARCH_SUBMITTED: "Load Search Submitted",
  CHROME_STORE_PAGE_OPENED: "Chrome store page opened",
  COPILOT_GUIDE_OPENED: "Copilot guide opened",
  NO_DOT: "No DOT",
  CONTACT_INFO_ADDED: "Contact info added",
} as const;

export const USER_PROPERTIES = {
  ACCOUNT_EMAIL: "Account Email",
  COMPANY_INFO: "Company Info",
  PHONE_NUMBER: "Phone Number",
  DOT: "DOT",
  MC: "MC",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  COMPANY_LEGAL_NAME: "Company Legal Name",
  COMPANY_ADDRESS: "Company Address",
  T_CODE: "T Code",
  DEFAULT_EQUIPMENT_TYPE: "Default Equipment Type",
  PERMITS: "Permits",
  GENERAL_PREFERENCES: "General Preferences",
} as const;

export const EVENT_PROPERTIES = {
  DEVICE_TYPE: "Device Type",
  REQUEST_INFO: "Request Info",
  FOUND_LOADS_AMOUNT: "Found Loads Amount",
  SOURCE: "Source",
} as const;
