import "styles/global.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Onboarding from "pages/Onboarding/Onboarding";
import RequestPage from "pages/RequestPage/RequestPage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import { useUser } from "hooks/useUser";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import Home from "pages/Home/Home";
import EditFieldPage from "pages/SettingsPage/EditFieldPage";
import Login from "pages/Login/Login";
import Signup from "pages/Signup/Signup";
import ProtectedRoute from "components/ProtectedRoute";
import { useAuth } from "context/AuthContext";
import mixpanel from "mixpanel-browser";
import InfoSaved from "pages/InfoSaved/InfoSaved";
import TagManager from "react-gtm-module";
import InstallExtensionPage from "pages/InstallExtensionPage/InstallExtensionPage";
import HowToPage from "pages/HowToPage/HowToPage";
import AccountCreated from "pages/AccountCreated/AccountCreated";
import { hasUserCompletedOnboarding } from "utils/helpers";

const App: React.FC = () => {
  const { user, loading } = useUser();
  const { currentUser } = useAuth();
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
    ignore_dnt: true,
  });

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID || "",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const updateVhUnit = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    updateVhUnit();
    window.addEventListener("resize", updateVhUnit);

    return () => {
      window.removeEventListener("resize", updateVhUnit);
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const isUserFinishedOnboardingAndLoggedIn = currentUser && user && hasUserCompletedOnboarding(user);

  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route
          path="/login"
          element={isUserFinishedOnboardingAndLoggedIn ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/signup"
          element={
            isUserFinishedOnboardingAndLoggedIn ? (
              <Navigate to="/" />
            ) : (
              <Navigate to="/onboarding" />
            )
          }
        />

        <Route
          path="/signup-form"
          element={isUserFinishedOnboardingAndLoggedIn ? <Navigate to="/" /> : <Signup />}
        />

        <Route
          path="/onboarding"
          element={isUserFinishedOnboardingAndLoggedIn ? <Navigate to="/" /> : <Onboarding />}
        />

        <Route path="/info-saved" element={<InfoSaved />} />
        <Route path="/account-created" element={<AccountCreated />} />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route
          path="/request"
          element={
            <ProtectedRoute>
              <RequestPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings/edit/:fieldKey"
          element={
            <ProtectedRoute>
              <EditFieldPage />
            </ProtectedRoute>
          }
        />

        <Route path="/install-extension" element={<InstallExtensionPage />} />
        <Route
          path="/extension-tutorial"
          element={<HowToPage mode="copilot" />}
        />
        <Route
          path="/assistant-tutorial"
          element={<HowToPage mode="assistant" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
