import { IDotInformationPayload } from "types/User";
import apiClient from "./config";

export const validateDotNumber = async (dot: string | number) => {
  try {
    const response = await apiClient.get(
      `ai-caller/dot-verify?dot_number=${dot}`,
    );
    return response.data as IDotInformationPayload;
  } catch (error) {
    throw new Error("Failed to fetch user profile");
  }
};
