import { toast } from "react-toastify";
import apiClient from "./config";

export interface UserProfileData {
  email: string;
  phone_number: string;
  dot: string;
  mc: string;
  first_name: string;
  last_name: string;
  company_legal_name: string;
  company_address: string;
  t_code: string;
  default_equipment_type?: string;
  permits: string[];
  general_preferences: string[];
}

export const createUserProfile = async (userData: any) => {
  try {
    const response = await apiClient.post("ai-caller/user-profile", userData);
    return response.data;
  } catch (error) {
    console.error("Error creating user profile:", error);
    toast.error("Failed to save your data. Please try again.");
    throw error;
  }
};
