import React, { useState } from "react";
import { Button } from "components/Button/Button";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import { ReactComponent as IconLeft } from "images/arrow_left.svg";
import { ReactComponent as IconArrowCircle } from "images/arrow_circle.svg";
import { ReactComponent as IconErrorCircle } from "images/error_circle.svg";
import { ReactComponent as IconCheckmark } from "images/check_circle.svg";
import { ReactComponent as IconBulb } from "images/bulb.svg";
import "./CallPrepareScreen.scss";
import { ISearchLoadsPayload } from "types/Payloads";
import { ILoad } from "types/Load";
import { trailerTypeOptions } from "pages/RequestPage/RequestPage";
import { SyncLoader } from "react-spinners";

interface ICallPrepareScreenProps {
  handleBack: () => void;
  handleEditRequest: () => void;
  loading?: boolean;
  handleSubmit: () => void;
  loads: ILoad[] | null;
  payload: ISearchLoadsPayload | null;
}

const formatDate = (dateString: string) => {
  if (!dateString) return "Unset date";

  const [year, month, day] = dateString.split("-").map(Number);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return `${day} ${monthNames[month - 1]} ${year}`;
};

const CallPrepareScreen: React.FC<ICallPrepareScreenProps> = ({
  handleBack,
  handleEditRequest,
  loading,
  handleSubmit,
  loads,
  payload,
}) => {
  const foundLoadsAmount = loads?.length || 0;

  return (
    <div className="form-wrapper">
      <div style={{ maxWidth: 572 }} className="form-screen form-container">
        <IconArrowLeft onClick={handleBack} className="back-icon" />
        <div onClick={handleBack} className="back-label offset">
          <IconLeft />
          <span>Back</span>
        </div>

        <div className="call-prepare">
          <div className="title">Preparing to start calling...</div>

          <div className="summary-box">
            <div className="summary-box__content">
              <div className="summary-box__title">Your request summary</div>

              <div className="summary-box__location">
                <div className="summary-box__location-item">
                  <div className="summary-box__location-label">
                    {payload?.pickup?.city}, {payload?.pickup?.state}
                  </div>
                  <div className="summary-box__location-value">
                    {payload?.pickup?.radius || "Unset"} mile radius
                  </div>
                </div>
                <IconArrowCircle className="arrow-circle" />
                <div className="summary-box__location-item">
                  <div className="summary-box__location-label">
                    {payload?.dropoff?.city}, {payload?.dropoff?.state}
                  </div>
                  <div className="summary-box__location-value">
                    {payload?.dropoff?.radius || "Unset"} mile radius
                  </div>
                </div>
              </div>

              <div className="summary-box__tags">
                {payload?.preferences?.weight_limit && (
                  <div className="summary-box__tag">
                    {payload?.preferences?.weight_limit}lbs
                  </div>
                )}
                {payload?.preferences?.equipment_type && (
                  <div className="summary-box__tag">
                    {trailerTypeOptions.find(
                      option =>
                        option.value === payload?.preferences?.equipment_type,
                    )?.label || "Unset"}
                  </div>
                )}
              </div>

              <div className="summary-box__pickup">
                <div className="summary-box__pickup-label">Pickup date</div>
                <div className="summary-box__pickup-value">
                  {`${formatDate(payload?.pickup?.start_datetime || "")} - ${formatDate(payload?.pickup?.end_datetime || "")}`}
                </div>
              </div>
            </div>
          </div>

          <FoundLoadsBox loadsAmount={foundLoadsAmount} loading={loading} />

          {foundLoadsAmount < 5 && !loading && (
            <WarningBox loadsAmount={foundLoadsAmount} />
          )}
        </div>
        <div className="buttons-container static top-margin">
          {!loading && (
            <Button
              text="Edit Request"
              variant="secondary"
              onClick={handleEditRequest}
            />
          )}
          {!loading && foundLoadsAmount === 0 ? null : (
            <Button
              text="Continue with Calls"
              variant="primary"
              className={loading ? "disabled" : ""}
              onClick={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface IFoundLoadsBoxProps {
  loadsAmount: number;
  loading?: boolean;
}
const FoundLoadsBox = ({ loadsAmount, loading }: IFoundLoadsBoxProps) => {
  const isErrorState = loadsAmount === 0 && !loading;

  return (
    <div className={`found-loads-box ${isErrorState && "error"}`}>
      {loading ? (
        <SyncLoader loading={true} color="#082980" size={8} />
      ) : (
        <>
          {isErrorState ? <IconErrorCircle /> : <IconCheckmark />}
          <div className="text">
            <span className="bold">{loadsAmount} loads</span> found based on
            your request
          </div>
        </>
      )}
    </div>
  );
};

const WarningBox = ({ loadsAmount }: { loadsAmount: number }) => {
  return (
    <div className="warning-box">
      <IconBulb />
      <div className="text">
        {loadsAmount === 0
          ? "It looks like we were not able to find any loads for your assistant to call based on your request. Try adjusting your requirements or requesting a different lane."
          : "Expanding your requirements might include more loads for the assistant to call. Select 'Edit Request' below to go back and update your request."}
      </div>
    </div>
  );
};

export default CallPrepareScreen;
