import React, { useState } from "react";
import { useAuth } from "context/AuthContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "components/Button/Button";
import "./Signup.scss";
import { toast } from "react-toastify";
import { AuthSchema } from "validation/schemas";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { ReactComponent as IconLock } from "images/lock.svg";
import { ReactComponent as IconMessage } from "images/message.svg";
import { ReactComponent as IconEyeClosed } from "images/eye_closed.svg";
import { ReactComponent as IconEye } from "images/eye.svg";
import { ReactComponent as IconLogo } from "images/big_logo.svg";
import { ReactComponent as IconCheckmark } from "images/checkmark.svg";
import { trackCompanyInfoSaved, trackNewSignup } from "utils/mixpanel";
import { createUserProfile } from "api/userProfile";
import { useUser } from "hooks/useUser";

export const formatSourceFromQueryParams = (source: string | null) => {
  if (source === "extension") {
    return "Extension";
  } else if (source === "landing") {
    return "Landing page";
  } else if (source === "chrome") {
    return "Chrome store";
  } else {
    return "Direct";
  }
}

const Signup: React.FC = () => {
  const { signup, loginWithGoogle, currentUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { payload } = location.state || {};

  const querySource = searchParams.get('source');
  const source = formatSourceFromQueryParams(querySource);

  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      await signup(values.email, values.password);
      toast.success("Account created successfully.");
      trackNewSignup(values.email, source);
      handleSuccessSignup(values.email);
      searchParams.delete('source');
      setSearchParams(searchParams);
    } catch (err) {
      toast.error("Failed to create an account.");
    }
    setLoading(false);
  };

  const handleGoogleSignup = async () => {
    setLoading(true);
    try {
      const res = await loginWithGoogle();
      const email = res?.user?.email;
      toast.success("Account created with Google successfully.");
      trackNewSignup(email, source);
      handleSuccessSignup(email);
      searchParams.delete('source');
      setSearchParams(searchParams);
    } catch (err) {
      toast.error("Failed to sign up with Google.");
    }
    setLoading(false);
  };

  const handleSuccessSignup = async (email: string) => {
    try {
      payload.email = email;
      const response = await createUserProfile(payload);

      if (response?.user_id) {
        navigate("/info-saved");
        setUser(response);
        trackCompanyInfoSaved(email, payload);
      }
    } catch (error) {
      console.error("Error during onboarding:", error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  if (loading) return <LoadingScreen />;

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={AuthSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="form-wrapper">
          <div className="form-screen form-container">
            <div className="fields-container">
              <div>
                <div className="title">Let’s Get Started!</div>
                <div className="subtitle">
                  Enter your credentials to continue
                </div>
              </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <IconMessage className="input-icon" />
                  <Field
                    type="email"
                    name="email"
                    className="input"
                    placeholder="Email"
                  />
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <IconLock className="input-icon" />
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="input"
                    placeholder="Password"
                  />
                  <div
                    className="input-icon-right"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <IconEyeClosed /> : <IconEye />}
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message"
                />
              </div>
              <Button text="Sign up" type="submit" />

              <div className="or-divider">
                <div className="divider" />
                <div className="text">or</div>
                <div className="divider" />
              </div>

              <Button
                text="Continue with Google"
                type="button"
                className="google-button"
                onClick={handleGoogleSignup}
                google
              />
            </div>

            <div className="account-text">
              <div className="text">Already have an account? </div>
              <div className="link" onClick={() => navigate("/login")}>
                Log in
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default Signup;
