import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button/Button";
import "./AccountCreated.scss";
import { ReactComponent as IconCheckmarkMinimal } from "images/checkmark_minimal.svg";

const AccountCreated: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="form-wrapper">
      <div className="informative-screen form-container">
        <div className="title-subtitle">
          <IconCheckmarkMinimal className="checkmark-minimal-icon center" />
          <div className="title center">Account created</div>
          <div className="subtitle center">
            Your account has been successfully created. You can update your company information at any time from your account page in the app.
          </div>
        </div>
        <div className="buttons-container">
          <Button text="Find Loads" variant="secondary" onClick={() => navigate('/request')} />
          <Button text="Go to Homepage" variant="primary" onClick={() => navigate('/')} />
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
