import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ReactComponent as IconLeft } from "images/arrow_left.svg";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import InputMask from "react-input-mask";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import AutoCompleteLocation from "components/AutoCompleteLocation/AutoCompleteLocation";
import { updateUserProfile } from "api/updateUserProfile";
import { permitOptions } from "utils/constants";
import { toast } from "react-toastify";
import "./EditFieldPage.scss";
import { Button } from "components/Button/Button";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { settingsSchema } from "validation/schemas";
import * as Yup from "yup";
import { trailerTypeOptions } from "pages/RequestPage/RequestPage";

const fieldLabels: { [key: string]: string } = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",
  phone_number: "Phone Number",
  company_legal_name: "Company Name",
  company_address: "Company Address",
  dot: "DOT",
  mc: "MC",
  t_code: "T-Code",
  default_equipment_type: "Equipment Type",
  permits: "Permits",
  general_preferences: "General Preferences",
  universal_questions: "Universal Question",
};

const EditFieldPage: React.FC = () => {
  const { fieldKey } = useParams<{ fieldKey: string }>();
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  if (!user || !fieldKey) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    [fieldKey]: user[fieldKey as keyof typeof user] || "",
  };

  const validationSchema = Yup.object({
    [fieldKey]: Yup.reach(settingsSchema, fieldKey),
  });

  const stringToList = (value: string) => {
    if (typeof value !== "string") return value;
    return value?.split(",").map((item: string) => item.trim());
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const updatedUser = { ...user, ...values };

      const payload = {
        id: updatedUser.user_id,
        email: updatedUser.email,
        phone_number: updatedUser.phone_number.replace(/\D/g, ""),
        dot: updatedUser.dot,
        mc: updatedUser.mc,
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        company_legal_name: updatedUser.company_legal_name,
        company_address: updatedUser.company_address,
        t_code: updatedUser.t_code,
        default_equipment_type: updatedUser.default_equipment_type,
        permits: updatedUser.permits,
        general_preferences: stringToList(updatedUser.general_preferences),
        universal_questions: stringToList(updatedUser.universal_questions),
      };

      await updateUserProfile(payload);
      setUser(updatedUser);
      navigate("/settings");
      toast?.success(`${fieldLabels[fieldKey]} updated successfully`);
    } catch (error) {
      console.error("Error updating user profile:", error);
      toast?.error(`Failed to update ${fieldLabels[fieldKey]}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/settings");
  };

  if (loading) return <LoadingScreen />;

  return (
    <div className="edit">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="edit__container">
              <div className="edit__content">
                <IconArrowLeft
                  onClick={() => navigate("/settings")}
                  className="back-icon"
                />

                <div
                  onClick={() => navigate("/settings")}
                  className="back-label"
                >
                  <IconLeft />
                  <span>Back to Settings</span>
                </div>
                <div className="title">Edit {fieldLabels[fieldKey]}</div>
                <div className="form-group">
                  <label htmlFor={fieldKey}>{fieldLabels[fieldKey]}</label>
                  {fieldKey === "phone_number" ? (
                    // @ts-ignore
                    <InputMask
                      mask="(999) 999-9999"
                      value={values.phone_number}
                      onChange={e =>
                        setFieldValue("phone_number", e.target.value)
                      }
                      maskChar={null}
                    >
                      {/* @ts-ignore */}
                      {inputProps => (
                        <Field
                          {...inputProps}
                          name="phone_number"
                          className="input"
                        />
                      )}
                    </InputMask>
                  ) : fieldKey === "permits" ? (
                    <CreatableSelect
                      isMulti
                      name="permits"
                      options={permitOptions}
                      value={(values.permits as string[]).map(
                        (value: string) => ({
                          value,
                          label: value,
                        }),
                      )}
                      onChange={selectedOptions =>
                        setFieldValue(
                          "permits",
                          selectedOptions
                            ? selectedOptions.map(option => option.value)
                            : [],
                        )
                      }
                    />
                  ) : fieldKey === "company_address" ? (
                    <AutoCompleteLocation
                      value={values.company_address as string}
                      onChange={address =>
                        setFieldValue("company_address", address)
                      }
                    />
                  ) : fieldKey === "default_equipment_type" ? (
                    <Select
                      name="default_equipment_type"
                      options={trailerTypeOptions}
                      value={trailerTypeOptions.find(
                        option => option.value === values.default_equipment_type,
                      )}
                      onChange={option =>
                        setFieldValue("default_equipment_type", option?.value)
                      }
                      classNamePrefix="select"
                      placeholder="Select equipment type"
                    />
                  ) : (
                    <Field id={fieldKey} name={fieldKey} className="input" />
                  )}
                  <ErrorMessage
                    name={fieldKey}
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="buttons-container static">
                <Button
                  text="Cancel"
                  variant="secondary"
                  onClick={handleCancel}
                  className="desktop-only"
                />
                <Button type="submit" text="Save" variant="primary" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditFieldPage;
