import { useState, useRef } from "react";
import { ReactComponent as IconPlay } from "images/play.svg";
import { ReactComponent as IconPause } from "images/pause.svg";
import { ReactComponent as IconAudioTrack } from "images/audio_track.svg";
import "./Audio.scss";
export const Audio = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const trackRef = useRef<HTMLDivElement | null>(null);

  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleAudioEnd = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const progressPercentage =
        (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(progressPercentage);
    }
  };

  const handleSeek = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!audioRef.current || !trackRef.current) return;

    const rect = trackRef.current.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const width = rect.width;

    const seekPercentage = (clickX / width) * 100;

    const newTime = (seekPercentage / 100) * audioRef.current.duration;
    audioRef.current.currentTime = newTime;

    setProgress(seekPercentage);
  };

  return (
    <div className="listen__audio">
      {isPlaying ? (
        <IconPause onClick={handlePlayPause} className="pause" />
      ) : (
        <IconPlay onClick={handlePlayPause} className="play" />
      )}

      <div
        className="audio-track-wrapper"
        ref={trackRef}
        onClick={handleSeek}
        style={{ cursor: "pointer" }}
      >
        <IconAudioTrack
          className="audio-track"
          style={{
            maskImage: `linear-gradient(to right, white ${progress}%, rgba(0, 0, 0, 0.5) ${progress}%)`,
            WebkitMaskImage: `linear-gradient(to right, white ${progress}%, rgba(0, 0, 0, 0.5) ${progress}%)`,
          }}
        />
      </div>

      <audio
        ref={audioRef}
        onEnded={handleAudioEnd}
        onTimeUpdate={handleTimeUpdate}
        src="./audio_sample.mp3"
      />
    </div>
  );
};
