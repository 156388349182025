import React from "react";
import "./InstallExtensionPage.scss";
import { Button } from "components/Button/Button";
import { ReactComponent as IconSearchLoads } from "images/search_loads.svg";
import ImageInstallExtension from "images/install-extension.png";
import { ReactComponent as IconArrowRight } from "images/external_link.svg";
import { useNavigate } from "react-router-dom";
import useIsMobile from "hooks/useIsMobile";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import { EVENT_PROPERTIES, EVENTS } from "utils/mixpanelConstants";

const InstallExtensionPage: React.FC = () => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleDownloadExtension = () => {
    if (isMobile) {
      toast.info("Please use a desktop browser to download the extension.");
      return;
    } else {
      mixpanel.track(EVENTS.NEW_SIGNUP, {
        [EVENT_PROPERTIES.SOURCE]: "Homepage"
      });

      window.open(
        process.env.REACT_APP_EXTENSION_LINK,
        "_blank",
      );
    }
  };

  return (
    <div className="install">
      <div className="install__container">
        <div className="install__content">
          <div className="install__title">
            Use EMPWR Assistant in Truckstop with <span className='highlight'>Copilot</span>
          </div>

          <div className="install__description">
            EMPWR Assistant Copilot lets you make calls to brokers using your
            assistant right from your Truckstop account.
          </div>

          <div className="install__description">
            To start using Copilot, install the extension from the Chrome Store
          </div>

          <Button text='Install Chrome Extension' onClick={handleDownloadExtension} endIcon={<IconArrowRight />} />
        </div>

        <div className="install__image">
          <img src={ImageInstallExtension} alt="Extension showcase" />
        </div>
      </div>
    </div>
  );
};

export default InstallExtensionPage;
