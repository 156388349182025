import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import { Button } from "components/Button/Button";
import { useAuth } from "context/AuthContext";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import "./MultiStepForm.scss";
import { ReactComponent as IconArrowLeft } from "images/chevron_left.svg";
import { ReactComponent as IconCheckmarkMinimal } from "images/checkmark_minimal.svg";
import { ReactComponent as IconLock } from "images/lock.svg";
import { ReactComponent as IconMessage } from "images/message.svg";
import { ReactComponent as IconEyeClosed } from "images/eye_closed.svg";
import { ReactComponent as IconEye } from "images/eye.svg";
import { ReactComponent as IconLogo } from "images/big_logo.svg";
import { ReactComponent as IconLeft } from "images/arrow_left.svg";
import {
  trackCompanyInfoSaved,
  trackContactInfoAdded,
  trackNewSignup,
  trackNoDotClicked,
} from "utils/mixpanel";
import { createUserProfile } from "api/userProfile";
import { formatSourceFromQueryParams } from "pages/Signup/Signup";
import { useUser } from "hooks/useUser";
import { validateDotNumber } from "api/dotValidation";
import { IDotInformationPayload } from "types/User";
import { updateUserProfile } from "api/updateUserProfile";
import { submitDotException } from "api/dotException";
import { AuthSchema } from "validation/schemas";
import { formatPhoneNumber, hasUserCompletedOnboarding } from "utils/helpers";

const dotSchema = Yup.object({
  dotNumber: Yup.string()
    .required("DOT number is required")
    .matches(/^\d+$/, "DOT must be numeric with no spaces"),
});

const dotNotFoundSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string().required("Email is required"),
});

const contactInfoSchema = Yup.object({
  contactFirstName: Yup.string().required("First Name is required"),
  contactLastName: Yup.string().required("Last Name is required"),
  contactPhone: Yup.string().required("Phone Number is required"),
});

const Onboarding: React.FC = () => {
  const navigate = useNavigate();
  const { signup, loginWithGoogle } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, setUser } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const querySource = searchParams.get("source");
  const source = formatSourceFromQueryParams(querySource);

  // Steps:
  // 1: Welcome screen
  // 2: Create account
  // 3: Enter your DOT
  // 4: DOT result screen (found or not found)
  // 5: Contact Information
  // 6: Account Created
  const [step, setStep] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(false);
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);
  const [showBeInTouchScreen, setShowBeInTouchScreen] = useState(false);

  const initialDotValues = { dotNumber: "" };

  const [dotNotFoundData, setDotNotFoundData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  // For step 4B (DOT found)
  const [dotData, setDotData] = useState<IDotInformationPayload | null>(null);
  const [dotNotFound, setDotNotFound] = useState<boolean>(false);

  // For step 5 (Contact Information)
  const [contactInfo, setContactInfo] = useState({
    contactFirstName: "",
    contactLastName: "",
    contactPhone: "",
  });

  useEffect(() => {
    if (!user) return;
    if (hasUserCompletedOnboarding(user)) {
      navigate("/");
    }
    if (user && !user.dot) {
      setStep(3);
    } else if (user && user.dot && !user.last_name) {
      setStep(5);
      setShowWelcomeBack(true);
    }
  }, []);

  const handleSignup = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      await signup(values.email, values.password);
      toast.success("Account created successfully.");
      trackNewSignup(values.email, source);
      handleSuccessSignup(values.email);
      searchParams.delete("source");
      setSearchParams(searchParams);
    } catch (err) {
      toast.error("Failed to create an account.");
    }
    setLoading(false);
  };

  const handleGoogleSignup = async () => {
    setLoading(true);
    try {
      const res = await loginWithGoogle();
      const email = res?.user?.email;
      toast.success("Account created with Google successfully.");
      trackNewSignup(email, source);
      handleSuccessSignup(email);
      searchParams.delete("source");
      setSearchParams(searchParams);
    } catch (err) {
      toast.error("Failed to sign up with Google.");
    }
    setLoading(false);
  };

  const handleSuccessSignup = async (email: string) => {
    try {
      const response = await createUserProfile({ email: email });

      if (response?.user_id) {
        setUser(response);
        setStep(3);
      }
    } catch (error) {
      console.error("Error during onboarding:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDotSubmit = async (values: { dotNumber: string }) => {
    setLoading(true);
    try {
      const dotNum = values.dotNumber;
      const response = await validateDotNumber(dotNum);

      if (response.dot_id) {
        setDotData(response);
        setDotNotFound(false);
      } else {
        setDotNotFound(true);
      }
    } catch (error) {
      console.error("DOT Verification failed:", error);
      setDotNotFound(true);
    }
    setLoading(false);
    setStep(4);
  };

  // Step 4A: If DOT not found, user enters info
  const handleDotNotFoundSubmit = async (values: any) => {
    submitDotException({
      type: dotNotFound ? "DOT_INVALID" : "DOT_MISSING",
      name: `${values.firstName} ${values.lastName}`,
      phone_number: values.phone.replace(/\D/g, ""),
      email: values.email,
    });
    setDotNotFoundData(values);
    setShowBeInTouchScreen(true);
  };

  // Step 4B: If DOT found, save the info
  const handleDotFoundNext = async () => {
    if (!dotData || !user) {
      toast.error("Failed to find DOT or User information. Please try again.");
      return;
    }
    const userWithUpdatedInformation = {
      ...user,
      dot: JSON.stringify(dotData.dot_id),
      company_legal_name: dotData.legal_name,
      company_address: dotData.address,
      mc: dotData.mcmx_id,
      dot_phone_number: dotData?.phone?.replace(/\D/g, ""),
    };
    setStep(5);
    const userObject = await updateUserProfile(userWithUpdatedInformation);

    if (userObject?.user_id) {
      setUser(userObject);
    }
  };

  // Step 5: Save contact info
  const handleContactInfoSubmit = async (values: any) => {
    setContactInfo(values);
    const userWithUpdatedInformation = {
      ...user,
      first_name: values.contactFirstName,
      last_name: values.contactLastName,
      phone_number: values.contactPhone.replace(/\D/g, ""),
      onboarding_complete: true, // We won't use this property on the FE side
    };

    trackContactInfoAdded(
      values.contactFirstName,
      values.contactLastName,
      values.contactPhone,
      user?.email,
    );

    navigate("/account-created");
    const userObject = await updateUserProfile(userWithUpdatedInformation);
    if (userObject?.user_id) {
      setUser(userObject);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleCloseBeInTouchScreen = () => {
    setShowBeInTouchScreen(false);
    setStep(3);
  };

  const handleCloseWelcomeBack = () => {
    setShowWelcomeBack(false);
  };

  const handleDontHaveDot = () => {
    setStep(4);
    setDotNotFound(false);
    setDotData(null);
    trackNoDotClicked(user?.email);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (showBeInTouchScreen) {
    return (
      <div className="form-wrapper">
        <div className="informative-screen form-container">
          <div className="title-subtitle">
            <IconCheckmarkMinimal className="checkmark-minimal-icon" />
            <div className="title center">
              Thanks! A member of the team will be in touch soon.
            </div>
          </div>
          <div className="buttons-container">
            <Button
              text="Done"
              variant="primary"
              onClick={handleCloseBeInTouchScreen}
            />
          </div>
        </div>
      </div>
    );
  }

  if (showWelcomeBack) {
    return (
      <div className="form-wrapper">
        <div className="informative-screen form-container">
          <div className="title-subtitle">
            <div className="title center">Welcome back</div>
            <div className="subtitle center">
              There's just a few more steps to set up your account and start
              using the EMPWR Assistant.
            </div>
          </div>
          <div className="buttons-container">
            <Button
              text="Finish Account Setup"
              variant="primary"
              onClick={handleCloseWelcomeBack}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="onboarding">
      {step === 1 && (
        <div className="form-wrapper primary-bg">
          <div className="informative-screen form-container">
            <div className="title-subtitle">
              <IconLogo className="big-logo" />
              <div className="title contrast center">
                Meet the EMPWR Assistant
              </div>
              <div className="subtitle center contrast">
                The EMPWR Assistant calls brokers for you so you can have more
                load options and spend more time on your business.
              </div>
            </div>
            <div className="buttons-container">
              <Button
                text="Log in"
                variant="secondary-reverse"
                onClick={() => navigate("/login")}
              />
              <Button
                text="Sign up"
                variant="contrast"
                onClick={() => setStep(2)}
              />
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={AuthSchema}
          onSubmit={handleSignup}
        >
          <Form>
            <div className="form-wrapper">
              <div className="form-screen form-container">
                <div className="fields-container">
                  <div>
                    <div className="step-label">1/3</div>
                    <div className="title">Create your account</div>
                    <div className="subtitle">
                      Enter an email and password to create an account and
                      continue.
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-wrapper">
                      <IconMessage className="input-icon" />
                      <Field
                        type="email"
                        name="email"
                        className="input"
                        placeholder="Email"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group">
                    <div className="input-wrapper">
                      <IconLock className="input-icon" />
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="input"
                        placeholder="Password"
                      />
                      <div
                        className="input-icon-right"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IconEyeClosed /> : <IconEye />}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <Button text="Sign up" type="submit" />

                  <div className="or-divider">
                    <div className="divider" />
                    <div className="text">or</div>
                    <div className="divider" />
                  </div>

                  <Button
                    text="Continue with Google"
                    type="button"
                    className="google-button"
                    onClick={handleGoogleSignup}
                    google
                  />
                </div>

                <div className="account-text">
                  <div className="text">Already have an account? </div>
                  <div className="link" onClick={() => navigate("/login")}>
                    Log in
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}

      {step === 3 && (
        <Formik
          initialValues={initialDotValues}
          validationSchema={dotSchema}
          onSubmit={handleDotSubmit}
        >
          {() => (
            <Form>
              <div className="form-wrapper">
                <div className="form-screen form-container">
                  <div className="fields-container">
                    <div>
                      <div className="step-label">2/3</div>
                      <div className="title">Create your account</div>
                      <div className="subtitle">Enter your DOT number</div>
                    </div>
                    <div className="form-group">
                      <Field
                        id="dotNumber"
                        name="dotNumber"
                        type="text"
                        className="input"
                        placeholder="Enter DOT"
                      />
                      <ErrorMessage
                        name="dotNumber"
                        component="div"
                        className="error-message"
                      />
                      <div
                        className="input-footer-link"
                        onClick={handleDontHaveDot}
                      >
                        I don't have a DOT
                      </div>
                    </div>
                    <div className="buttons-container">
                      <Button type="submit" text="Submit" variant="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {/* Step 4 => if dotNotFound === false => show DOT found info; else show a form for user to fill out  */}
      {step === 4 && !dotNotFound && dotData && (
        <div className="form-wrapper">
          <div className="form-screen form-container">
            <IconArrowLeft onClick={() => setStep(3)} className="back-icon" />
            <div onClick={() => setStep(3)} className="back-label offset">
              <IconLeft />
              <span>Back</span>
            </div>

            <div className="fields-container">
              <div>
                <div className="title">
                  We found the following company information.
                </div>
                <div className="subtitle">
                  You can edit these details if necessary from your account page
                  in the app.
                </div>
              </div>
              <div className="outline-form-container">
                <div className="info-field">
                  <div className="info-field__label">Company Name</div>
                  <div className="info-field__value">{dotData.legal_name}</div>
                </div>

                <div className="info-field__row">
                  <div className="info-field">
                    <div className="info-field__label">DOT</div>
                    <div className="info-field__value">{dotData.dot_id}</div>
                  </div>
                  <div className="info-field">
                    <div className="info-field__label">MC</div>
                    <div className="info-field__value">{dotData.mcmx_id}</div>
                  </div>
                </div>

                <div className="info-field">
                  <div className="info-field__label">Phone</div>
                  <div className="info-field__value">{dotData.phone}</div>
                </div>

                <div className="info-field">
                  <div className="info-field__label">Business Location</div>
                  <div className="info-field__value">{dotData.address}</div>
                </div>
              </div>
            </div>
            <div className="buttons-container">
              <Button
                text="Looks Good"
                variant="primary"
                onClick={handleDotFoundNext}
              />
            </div>
          </div>
        </div>
      )}

      {step === 4 && (dotNotFound || !dotData) && (
        <Formik
          initialValues={dotNotFoundData}
          validationSchema={dotNotFoundSchema}
          onSubmit={handleDotNotFoundSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-wrapper">
                <div className="form-screen form-container">
                  <IconArrowLeft
                    onClick={() => setStep(3)}
                    className="back-icon"
                  />
                  <div onClick={() => setStep(3)} className="back-label offset">
                    <IconLeft />
                    <span>Back</span>
                  </div>
                  <div className="fields-container">
                    <div>
                      <div className="title">
                        {dotNotFound
                          ? "We weren't able to find any information matching this DOT number."
                          : "Don't have a DOT?"}
                      </div>
                      <div className="subtitle">
                        {dotNotFound
                          ? "Please provide your email and/or phone number below and a member of our team will reach out to you to directly."
                          : "If you don't have a DOT because your business does not require one, please provide your email and/or phone below and a member of our team will reach out directly to set you up."}
                      </div>
                    </div>

                    <div className="outline-form-container">
                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="input"
                            placeholder="John"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <Field
                            id="lastName"
                            name="lastName"
                            type="text"
                            className="input"
                            placeholder="Doe"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <div>
                          <InputMask
                            placeholder="(000) 000-0000"
                            mask="(999) 999-9999"
                            value={values.phone}
                            onChange={e =>
                              setFieldValue("phone", e.target.value)
                            }
                            maskChar={null}
                          >
                            {/* @ts-ignore  */}
                            {inputProps => (
                              <Field
                                {...inputProps}
                                id="phone"
                                name="phone"
                                type="text"
                                className="input"
                              />
                            )}
                          </InputMask>

                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          className="input"
                          placeholder="example@gmail.com"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="buttons-container static-mobile">
                      <Button type="submit" text="Submit" variant="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {step === 5 && (
        <Formik
          initialValues={contactInfo}
          validationSchema={contactInfoSchema}
          onSubmit={handleContactInfoSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-wrapper">
                <div className="form-screen form-container">
                  <div className="fields-container">
                    <div>
                      <div className="step-label">3/3</div>
                      <div className="title">Create your account</div>
                      <div className="subtitle">
                        Enter your contact information
                      </div>
                    </div>
                    <div className="outline-form-container">
                      <div className="row-fields">
                        <div className="form-group">
                          <label htmlFor="contactFirstName">First Name</label>
                          <Field
                            id="contactFirstName"
                            name="contactFirstName"
                            type="text"
                            className="input"
                            placeholder="John"
                          />
                          <ErrorMessage
                            name="contactFirstName"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="contactLastName">Last Name</label>
                          <Field
                            id="contactLastName"
                            name="contactLastName"
                            type="text"
                            className="input"
                            placeholder="Doe"
                          />
                          <ErrorMessage
                            name="contactLastName"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="contactPhone">
                          Contact Phone Number
                        </label>
                        <div>
                          <InputMask
                            placeholder="(000) 000-0000"
                            mask="(999) 999-9999"
                            value={values.contactPhone}
                            onChange={e =>
                              setFieldValue("contactPhone", e.target.value)
                            }
                            maskChar={null}
                          >
                            {/* @ts-ignore  */}
                            {inputProps => (
                              <Field
                                {...inputProps}
                                id="contactPhone"
                                name="contactPhone"
                                type="text"
                                className="input"
                              />
                            )}
                          </InputMask>

                          {user?.dot_phone_number && (
                            <div
                              onClick={() =>
                                setFieldValue(
                                  "contactPhone",
                                  user?.dot_phone_number,
                                )
                              }
                              className="input-footer-link"
                            >
                              Use {formatPhoneNumber(user?.dot_phone_number)}
                            </div>
                          )}

                          <ErrorMessage
                            name="contactPhone"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-container">
                    <Button type="submit" text="Submit" variant="primary" />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default Onboarding;
