import { User } from "types/User";

export const getDeviceType = (): "desktop" | "mobile" | "tablet" => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/mobile|android|iphone|ipod|blackberry|phone/.test(userAgent)) {
    return "mobile";
  } else if (/ipad|tablet/.test(userAgent)) {
    return "tablet";
  }
  return "desktop";
};

export const formatMcNumber = (mcNumber?: string) => {
  if (!mcNumber) return mcNumber;
  return mcNumber.replace("MC-", "");
};

export const hasUserCompletedOnboarding = (user: User) => {
  return !!user.dot && !!user.mc && !!user.first_name;
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber || phoneNumber.length !== 10) {
    return phoneNumber;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};
