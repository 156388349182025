import React from "react";
import "./SupportPopup.scss";
import { ReactComponent as IconCopy } from "images/copy.svg";
import { ReactComponent as IconPhone } from "images/phone.svg";
import { ReactComponent as IconEmail } from "images/email_small.svg";
import { ReactComponent as IconClose } from "images/close.svg";
import useIsMobile from "hooks/useIsMobile";
import { toast } from "react-toastify";

const SupportPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const isMobile = useIsMobile();
  const phoneNumberDisplay = "+1 (707) 6574 8372";
  const phoneNumberCopy = "+170765748372";
  const email = "example@gmail.com";

  const handleCopy = (text: string, label: string) => {
    navigator.clipboard.writeText(text);
    toast.success(`${label} copied to clipboard`);
  };

  const handleCallUs = () => {
    window.open(`tel:${phoneNumberCopy}`, "_self");
  };

  return (
    <div className="support-popup">
      <div className="support-popup__overlay" onClick={onClose}></div>
      <div className="support-popup__container">
        <button className="support-popup__close-button" onClick={onClose}>
          <IconClose />
        </button>
        <div className="support-popup__content">
          <div className="support-popup__title">Connect with Our Team</div>
          <div className="support-popup__subtitle">
            Need help or have questions? Contact us by email or phone for
            assistance.
          </div>
          <div className="support-popup__items">
            <div className="support-popup__item">
              <div className="support-popup__icon-wrapper">
                <IconEmail />
              </div>
              <div className="support-popup__contact">
                <span>{email}</span>
                <button
                  className="support-popup__copy-button"
                  onClick={() => handleCopy(email, "Email")}
                >
                  <IconCopy />
                </button>
              </div>
            </div>
            {!isMobile && (
              <div className="support-popup__item">
                <div className="support-popup__icon-wrapper">
                  <IconPhone />
                </div>
                <div className="support-popup__contact">
                  <span>{phoneNumberDisplay}</span>
                  <button
                    className="support-popup__copy-button"
                    onClick={() => handleCopy(phoneNumberCopy, "Phone number")}
                  >
                    <IconCopy />
                  </button>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="support-popup__item mobile">
                <button
                  className="support-popup__call-button"
                  onClick={handleCallUs}
                >
                  <IconPhone />
                  <span>Call Us</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPopup;
