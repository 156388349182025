import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useUser } from "hooks/useUser";
import { hasUserCompletedOnboarding } from "utils/helpers";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user } = useUser();
  const { currentUser } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const isExtensionInstalled = searchParams.get("extension-installed");

  useEffect(() => {
    if (isExtensionInstalled && isExtensionInstalled === "true") {
      searchParams.delete("extension-installed");
      setSearchParams(searchParams);
      localStorage.setItem("isExtensionInstalled", "true");
    }
  }, [isExtensionInstalled, searchParams, setSearchParams]);

  if (user && !hasUserCompletedOnboarding(user)) {
    return <Navigate to="/onboarding" />;
  }

  if (!currentUser || !user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
