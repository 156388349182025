import React, { useEffect, useState } from "react";
import "./Home.scss";
import { Button } from "components/Button/Button";
import { ReactComponent as IconSearchLoads } from "images/search_loads.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { useAuth } from "context/AuthContext";
import { ReactComponent as IconLogout } from "images/logout.svg";
import { ReactComponent as IconLogo } from "images/logo_square.svg";
import { ReactComponent as IconHeadphone } from "images/headphone.svg";
import { ReactComponent as IconPuzzle } from "images/puzzle.svg";
import { ReactComponent as IconArrowRight } from "images/external_link.svg";
import useIsMobile from "hooks/useIsMobile";
import { toast } from "react-toastify";
import SupportPopup from "components/SupportPoup/SupportPopup";
import { trackCopilotGuideOpened } from "utils/mixpanel";
import { formatMcNumber } from "utils/helpers";
import { updateUserProfile } from "api/updateUserProfile";

const Home: React.FC = () => {
  const { user, setUser } = useUser();
  const { logout } = useAuth();
  const isMobile = useIsMobile();
  const [isSupportPopupOpen, setIsSupportPopupOpen] = useState(false);
  const triggerExtensionInstalledFlag = localStorage.getItem("isExtensionInstalled");

  const isCopilotInstalled = user?.installed_chrome_extension;

  useEffect(() => {
    if (triggerExtensionInstalledFlag && !isCopilotInstalled) {
      updateUserProfile({ ...user, installed_chrome_extension: true });
      setUser({ ...user, installed_chrome_extension: true });

      localStorage.removeItem("isExtensionInstalled");
    }
  }, [isCopilotInstalled, triggerExtensionInstalledFlag]);

  const navigate = useNavigate();

  const handleFindLoads = () => {
    navigate("/request");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleLaunchTruckstop = () => {
    window.open("https://main.truckstop.com/app/search/loads", "_blank");
  };

  const handleDownloadExtension = () => {
    if (isMobile) {
      toast.info("Please use a desktop browser to download the extension.");
      return;
    } else {
      navigate("/install-extension");
    }
  };

  const handleOpenSupportPopup = () => {
    setIsSupportPopupOpen(true);
  };

  const handleCloseSupportPopup = () => {
    setIsSupportPopupOpen(false);
  };

  const handleOpenCopilotTutorial = () => {
    trackCopilotGuideOpened(user?.email);
    navigate("/extension-tutorial");
  }

  return (
    <div className="home">
      {isSupportPopupOpen && <SupportPopup onClose={handleCloseSupportPopup} />}
      <div className="home__container">
        <div className="home__header">
          <div className="home__header-left">
            <IconLogo className="home__header-logo" />
            <div className="home__header-title">EMPWR Trucking Assistant</div>
          </div>

          <div className="home__header-right">
            <div
              onClick={handleOpenSupportPopup}
              className="home__header-button"
            >
              <span>Support</span>
              <IconHeadphone />
            </div>
            <div onClick={logout} className="home__header-button">
              <span>Logout</span>
              <IconLogout />
            </div>
          </div>
        </div>

        <div className="home__main-card">
          <div className="home__main-card-header">
            <div className="home__main-card-title">
              {user?.company_legal_name}
            </div>
            <button onClick={handleSettings} className="home__main-card-button">
              Configure Your Assistant
            </button>
          </div>
          <div className="home__main-card-info">
            <div className="home__card-info-item">
              <span className="home__card-info-title">DOT:</span>
              <span className="home__card-info-description">
                {user?.dot || "-"}
              </span>
            </div>
            <div className="home__card-info-item">
              <span className="home__card-info-title">MC:</span>
              <span className="home__card-info-description">
                {formatMcNumber(user?.mc) || "-"}
              </span>
            </div>
          </div>
        </div>

        <div className="home__card">
          <div className="home__card-text">
            <div className="home__card-header">
              <IconSearchLoads />
              <span>Find Spot Freight</span>
            </div>
            <div className="home__card-description">
              Your EMPWR Assistant contacts brokers for you to verify spot
              loads.
            </div>
          </div>

          <div className="home__card-buttons">
            <Button text="Start a New Request" onClick={handleFindLoads} />
            <Button
              onClick={() => navigate("/assistant-tutorial")}
              text="Learn More"
              variant="secondary"
            />
          </div>
        </div>

        <div className="home__card">
          <div className="home__card-text">
            <div className="home__card-header">
              <IconPuzzle />
              <span>Use Copilot</span>
            </div>
            <div className="home__card-description">
              Use your EMPWR Assistant directly from your Truckstop loadboard
              with Copilot.
            </div>
          </div>

          <div className="home__card-buttons">
            <Button
              endIcon={isCopilotInstalled ? <IconArrowRight /> : null}
              text={
                isCopilotInstalled ? "Use Copilot in Truckstop" : "Get Copilot"
              }
              onClick={
                isCopilotInstalled
                  ? handleLaunchTruckstop
                  : handleDownloadExtension
              }
            />
            {!isCopilotInstalled &&
              <Button
                endIcon={<IconArrowRight />}
                text="Use Copilot in Truckstop"
                onClick={handleLaunchTruckstop}
              />
            }
            <Button
              onClick={handleOpenCopilotTutorial}
              text="Learn More"
              variant="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
